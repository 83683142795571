import {
  AudioError,
  PlaybackSpeed,
  PlaybackState,
  Player,
  Playlist,
  PlaylistEventHandler,
  PlaylistEventType,
  PlaylistSegmentMetadata,
  Sonic,
  TrackingData,
  TrackingEvent,
} from '../../types';
import { trackUserEvent } from '../tracking';
import { debug } from './debug';
import { PlaylistSegment } from './PlaylistSegment';
import { SegmentPlayer } from './SegmentPlayer';
import { SonicPlayer } from './SonicPlayer';

type SonicsConfig = Record<Sonic, string>;
type Sonics = Record<Sonic, Player>;
type SonicFinishedCallback = () => void;

const DEFAULT_SKIP_BACKWARD_OFFSET = 10;
const DEFAULT_SKIP_FORWARD_OFFSET = 10;
const UNINITIALIZED_PLAYLIST: Playlist = { createdAt: '', segments: [], id: '_not yet set_' };
const POSITION_UPDATE_INTERVAL = 100;

class PlaylistPlayer {
  private listeners: Map<PlaylistEventType, Set<PlaylistEventHandler>> = new Map();
  private _playbackState: PlaybackState = PlaybackState.None;
  private _speed: PlaybackSpeed = PlaybackSpeed.One;
  private _playlist: Playlist = UNINITIALIZED_PLAYLIST;
  private currentSegmentIndex: number = 0;
  private segmentPlayers?: Array<SegmentPlayer>;
  private mediaControlsInitialized: boolean = false;
  private activePlayer?: Player;
  private activePlayerCallback?: SonicFinishedCallback;
  private sonicPlayers?: Sonics;
  private positionIntervalId?: NodeJS.Timer;

  private debug(message: string) {
    debug(`(PlaylistPlayer): ${message}`);
  }

  private startPositionInterval() {
    this.clearPositionInterval();
    this.positionIntervalId = setInterval(() => {
      this.firePlaylistEvent(PlaylistEventType.PositionChanged, { position: this.currentSegmentPosition });
    }, POSITION_UPDATE_INTERVAL);
  }

  private clearPositionInterval() {
    if (this.positionIntervalId) {
      clearInterval(this.positionIntervalId);
      this.positionIntervalId = undefined;
    }
  }

  private firePlaylistEvent(eventType: PlaylistEventType, data: object = {}) {
    const event = { type: eventType, ...data };
    this.listeners.get(event.type)?.forEach((callback) => {
      callback(event);
    });
  }

  private buildSegmentPlayers(playlistSegments: Array<PlaylistSegment>): Array<SegmentPlayer> {
    const players: Array<SegmentPlayer> = [];
    for (const segment of playlistSegments) {
      const segmentPlayer = new SegmentPlayer({
        segment,
        speed: this._speed,

        onPlay: () => {
          this.currentSegment.playbackStarted = true;
          this.setPlaybackState(PlaybackState.Playing);
        },

        onPause: () => {
          this.setPlaybackState(PlaybackState.Paused);
        },

        onIntroEnd: () => {},

        onIntroError: (error: AudioError) => {},

        onSegmentEnd: () => {
          this.trackCurrentSegmentEvent('completed', segment);
          this.activePlayer = undefined;
          this.currentSegment.playbackCompleted = true;
          this.autoAdvanceTrack();
        },

        onSegmentError: (error: AudioError) => {
          // Segment errors can come at any time, because the audio will fire them as soon as get its a load error, even if it's not attempting playback.
          // For that reason, we only track it as a playError if we're currently trying to play this segment's audio
          console.error(`Segment audio error on ${segment.id}`, error);
          if (Object.is(segmentPlayer, this.activePlayer)) {
            this.trackCurrentSegmentEvent('playError', { error });
            this.activePlayer = undefined;
            this.autoAdvanceTrack();
          }
        },

        onSegmentLoad: () => {
          // I've observed that the media controls are not correct if there is no audio on the page, so we wait until we successfully load the first audio before setting up the controls
          this.setupMediaControls();
        },
      });

      segmentPlayer.loadAudio();
      players.push(segmentPlayer);
    }
    return players;
  }

  private get segments(): Array<PlaylistSegment> | undefined {
    return this.playlist?.segments;
  }

  private get currentSegment() {
    if (!this.segments) {
      throw new Error('Playlist not set');
    }
    return this.segments[this.currentSegmentIndex];
  }

  private get currentSegmentPlayer(): SegmentPlayer {
    if (!this.segmentPlayers) {
      throw new Error('Playlist not set');
    }
    return this.segmentPlayers[this.currentSegmentIndex];
  }

  private setPlaybackState(newPlaybackState: PlaybackState) {
    this._playbackState = newPlaybackState;
    if (navigator.mediaSession) {
      this.debug(`Updating mediaSession ${newPlaybackState}`);
      switch (newPlaybackState) {
        case PlaybackState.None:
        case PlaybackState.Stopped:
          navigator.mediaSession.playbackState = 'none';
          break;
        case PlaybackState.Buffering:
        case PlaybackState.Playing:
          navigator.mediaSession.playbackState = 'playing';
          break;
        case PlaybackState.Paused:
          navigator.mediaSession.playbackState = 'paused';
      }
    }
    this.updateMediaMetadata();
    this.firePlaylistEvent(PlaylistEventType.StateChanged, { state: newPlaybackState });
  }

  /**
   * Attempts to play sonic and if successful in trying, sets up the callback invoked after playback finishes by
   * completing playback or by error. The callback is only called if `true` is returned
   */
  private playSonic(sonic: Sonic, callback?: SonicFinishedCallback): boolean {
    if (!this.sonicPlayers) {
      throw new Error('Sonics not configured');
    }
    const sonicPlayer: Player = this.sonicPlayers[sonic];
    const sonicAttempted = sonicPlayer.play();
    if (sonicAttempted) {
      this.activePlayer = sonicPlayer;
      this.activePlayerCallback = callback;
    }
    return sonicAttempted;
  }

  private handlePlaylistEnd() {
    this.setPlaybackState(PlaybackState.Stopped);
    this.playSonic(Sonic.End);
    this.clearMediaMetadata();
    this.currentSegmentIndex = 0;
  }

  private continuePlaybackFromCurrentSegment({
    sonic,
    withIntro = true,
  }: {
    sonic?: Sonic;
    withIntro?: boolean;
  } = {}): void {
    this.updateMediaMetadata();
    this.firePlaylistEvent(PlaylistEventType.SegmentChanged);
    this.startPositionInterval();

    this.setPlaybackState(PlaybackState.Buffering);

    if (sonic) {
      const sonicAttempted = this.playSonic(sonic, () => this.continuePlaybackFromCurrentSegment({ withIntro }));
      if (sonicAttempted) {
        return;
      }
    }

    const segmentAttempted = this.currentSegmentPlayer.play(withIntro);

    if (segmentAttempted) {
      this.trackCurrentSegmentEvent('play');
      this.activePlayer = this.currentSegmentPlayer;
    } else {
      this.trackCurrentSegmentEvent('playError', { error: this.currentSegmentPlayer.error });
      this.autoAdvanceTrack();
    }
  }

  private autoAdvanceTrack() {
    if (!this.segments) {
      throw new Error('Playlist not set');
    }
    const nextTrackIndex = this.currentSegmentIndex + 1;
    if (nextTrackIndex >= this.segments.length) {
      this.handlePlaylistEnd();
    } else {
      this.currentSegmentIndex = nextTrackIndex;
      this.continuePlaybackFromCurrentSegment({ sonic: Sonic.Next });
    }
  }

  private pauseCurrentSegment() {
    // It's possible that playback hasn't started yet, so clear out the pending play
    this.activePlayer = undefined;

    // Always call pause on the current segment, even though it's possible that it's not yet loaded or playing, in which case it's a no-op
    this.currentSegmentPlayer.pause();
    this.trackCurrentSegmentEvent('pause');
    this.clearPositionInterval();
  }

  private updateMediaMetadata(): void {
    if ('mediaSession' in navigator) {
      this.debug(`Updating mediaSession to ${this.currentSegment.title}`);
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.currentSegment.title,
        artist: 'Jam',
        album: this.currentSegment.stream.name as string,
        artwork: [{ src: this.currentSegment.watermarkedImageUrl, type: 'image/png' }],
      });
    }
  }

  private clearMediaMetadata(): void {
    if ('mediaSession' in navigator) {
      this.debug(`Clearing mediaSession`);
      navigator.mediaSession.metadata = null;
    }
  }

  /**
   * Fires a tracking event for the currently playing (or paused) segment
   *
   * @param trackingEvent
   * @param addlData
   */
  private trackCurrentSegmentEvent(trackingEvent: TrackingEvent, addlData?: TrackingData) {
    trackUserEvent(trackingEvent, {
      fileUrl: this.currentSegment.playbackUrl,
      currentTime: this.currentSegmentPosition,
      duration: this.currentSegment.duration,
      segmentId: this.currentSegment.id,
      ...addlData,
    });
  }

  private setupMediaControls() {
    if (this.mediaControlsInitialized) {
      return;
    }

    // Configure media controls. The documentation on this is sparse. Here's what we've observed:

    // 1. The order of adding listeners matters. We need to add support for everything we expect in players, but we are
    //    trying to nudge the player to show a skip back N (defaults to 10 on iOS) seconds and a next track button,
    //    which is why those are first. Not all players, e.g., Pete's Subaru, respect these preferences.
    // 2. The controls don't work correctly if initialized too soon, e.g., the wrong buttons may appear. I suspect that it
    //    doesn't work correctly if there is not yet an audio element on the page.

    if (navigator?.mediaSession) {
      navigator.mediaSession.setActionHandler('play', () => {
        this.play();
      });
      navigator.mediaSession.setActionHandler('pause', () => {
        this.pause();
      });
      navigator.mediaSession.setActionHandler('seekbackward', (eventProperties) => {
        this.skipBackward(eventProperties.seekOffset || DEFAULT_SKIP_BACKWARD_OFFSET);
      });
      navigator.mediaSession.setActionHandler('seekforward', (eventProperties) => {
        this.skipForward(eventProperties.seekOffset || DEFAULT_SKIP_FORWARD_OFFSET);
      });
      navigator.mediaSession.setActionHandler('nexttrack', () => {
        this.skipToNextSegment();
      });
      navigator.mediaSession.setActionHandler('seekto', (eventProperties) => {
        if (eventProperties.seekTime) {
          this.seekTo(eventProperties.seekTime);
        } else {
          console.error('MediaSession seekto action with no seekTime', eventProperties);
        }
      });
      navigator.mediaSession.setActionHandler('previoustrack', () => {
        this.skipToPreviousSegment();
      });
    }
    this.mediaControlsInitialized = true;
  }

  get currentSegmentId(): string {
    return this.currentSegment.id;
  }

  get currentSegmentPosition(): number {
    return this.currentSegmentPlayer.position;
  }

  get isPlaying(): boolean {
    return this._playbackState === PlaybackState.Playing;
  }

  get playbackState(): PlaybackState {
    return this._playbackState;
  }

  get currentPlaylistSegmentMetadata(): PlaylistSegmentMetadata | undefined {
    return this.hasPlaylist
      ? {
          percentPlayed: this.currentSegmentPlayer.percentPlayed,
          timeRemaining: this.currentSegmentPlayer.timeRemaining,
          playlistSegment: this.currentSegment,
          duration: this.currentSegmentPlayer.duration,
          position: this.currentSegmentPlayer.duration - this.currentSegmentPlayer.timeRemaining,
        }
      : undefined;
  }

  get nextPlaylistSegment(): PlaylistSegment | undefined {
    if (!this.segments) {
      return undefined;
    }

    return this.currentSegmentIndex < this.segments.length ? this.segments[this.currentSegmentIndex + 1] : undefined;
  }

  get hasPlaylist(): boolean {
    return this._playlist !== UNINITIALIZED_PLAYLIST;
  }

  get speed(): PlaybackSpeed {
    return this._speed;
  }

  set speed(speed: PlaybackSpeed) {
    this._speed = speed;

    if (this.hasPlaylist) {
      this.trackCurrentSegmentEvent('speedChange', { oldSpeed: this._speed, newSpeed: speed });
      for (const player of this.segmentPlayers as SegmentPlayer[]) {
        player.speed = speed;
      }
    }

    this.firePlaylistEvent(PlaylistEventType.SpeedChanged, { speed });
  }

  /** Starts playback from the current position, which is initially the beginning of the playlist */
  play() {
    this.continuePlaybackFromCurrentSegment({ sonic: Sonic.Start });
  }

  /** Pauses playback of the current segment */
  pause() {
    this.pauseCurrentSegment();
  }

  seekTo(position: number) {
    const currentPosition = this.currentSegmentPlayer.position;
    this.trackCurrentSegmentEvent('seek', { oldPosition: currentPosition, newPosition: position });
    this.currentSegmentPlayer.seek(position);
  }

  skipToSegment(id: string) {
    if (!this.segments) {
      throw new Error('Playlist not set');
    }

    const segmentIndex: number = this.segments.findIndex((segment: PlaylistSegment) => segment.id === id);
    if (segmentIndex === -1) {
      console.error(`Attempted to skip to segment ${id}, which is not found in the playlist`);
      return;
    }
    this.currentSegmentPlayer.stop();
    this.currentSegmentIndex = segmentIndex;

    this.continuePlaybackFromCurrentSegment({ sonic: Sonic.Next, withIntro: false });
  }

  // Use cases not covered:
  //  - Skipped segment - this just goes to the one before it
  //  - Going back more than one segment. If the previous segment is so short that we'd need to skip it entirely, we just
  //    go back to the beginning of that segment
  skipBackward(secondsToSkip: number) {
    this.trackCurrentSegmentEvent('skipBackward');

    const currentPos = this.currentSegmentPlayer.position;
    const newPos: number = currentPos - secondsToSkip;
    if (newPos > 0) {
      this.currentSegmentPlayer.seek(newPos);
    } else if (this.currentSegmentIndex === 0) {
      this.currentSegmentPlayer.seek(0);
    } else {
      this.currentSegmentPlayer.stop();
      this.currentSegmentIndex = this.currentSegmentIndex - 1;
      let prevTrackDuration: number = this.currentSegmentPlayer.duration;
      if (typeof prevTrackDuration === 'string') {
        // why is this continuing to come back as a string for me I do not understand
        prevTrackDuration = parseFloat(prevTrackDuration);
      }
      let posInPrevTrack: number = prevTrackDuration + newPos;
      if (posInPrevTrack < 0) {
        posInPrevTrack = 0;
      }

      this.currentSegmentPlayer.seek(posInPrevTrack);
      this.continuePlaybackFromCurrentSegment({ withIntro: false });
    }
  }

  skipForward(secondsToSkip: number) {
    if (!this.segments) {
      throw new Error('Playlist not set');
    }

    this.trackCurrentSegmentEvent('skipForward');

    const currentPos = this.currentSegmentPlayer.position;
    const newPos = currentPos + secondsToSkip;
    if (newPos <= this.currentSegmentPlayer.duration) {
      this.currentSegmentPlayer.seek(newPos);
    } else if (newPos > this.currentSegmentPlayer.duration && this.currentSegmentIndex !== this.segments.length - 1) {
      this.currentSegmentPlayer.stop();
      this.currentSegmentIndex = this.currentSegmentIndex + 1;
      const posInNextTrack = newPos - this.currentSegmentPlayer.duration;
      this.currentSegmentPlayer.seek(posInNextTrack);
      this.continuePlaybackFromCurrentSegment({ withIntro: false });
    } else {
      this.currentSegmentPlayer.stop();
    }
  }

  skipToPreviousSegment() {
    this.trackCurrentSegmentEvent('back');

    this.currentSegmentPlayer.stop();

    let previousIndex = this.currentSegmentIndex - 1;
    if (previousIndex < 0) {
      previousIndex = 0;
    }
    this.currentSegmentIndex = previousIndex;
    this.continuePlaybackFromCurrentSegment({ withIntro: false });
  }

  skipToNextSegment() {
    if (!this.segments) {
      throw new Error('Playlist not set');
    }
    this.trackCurrentSegmentEvent('next');

    this.currentSegmentPlayer.stop();

    const nextIndex = this.currentSegmentIndex + 1;
    if (nextIndex < this.segments.length) {
      this.currentSegmentIndex = nextIndex;
      this.continuePlaybackFromCurrentSegment({ withIntro: true });
    } else {
      this.handlePlaylistEnd();
    }
  }

  /**
   * Must be called before any other method and only once.
   *
   * @param sonicsConfig
   */
  configureSonics(sonicsConfig: SonicsConfig) {
    if (this.sonicPlayers) {
      throw new Error('Sonics already configured');
    }

    this.sonicPlayers = {} as Sonics;
    for (const config in sonicsConfig) {
      const sonicType: Sonic = config as unknown as Sonic;
      const sonicPlayer = new SonicPlayer({
        audioSrc: sonicsConfig[sonicType],

        onEnd: () => {
          if (Object.is(sonicPlayer, this.activePlayer)) {
            this.activePlayer = undefined;
            if (this.activePlayerCallback) {
              this.activePlayerCallback();
              this.activePlayerCallback = undefined;
            }
          }
        },

        onError: (error: AudioError) => {
          console.error(`Sonic [${sonicType}] error`, error);
          if (Object.is(sonicPlayer, this.activePlayer)) {
            this.activePlayer = undefined;
            if (this.activePlayerCallback) {
              this.activePlayerCallback();
              this.activePlayerCallback = undefined;
            }
          }
        },
      });

      this.sonicPlayers[sonicType] = sonicPlayer;
    }
  }

  get playlist(): Playlist {
    return this._playlist;
  }

  set playlist(newPlaylist: Playlist) {
    if (this._playlist.id === newPlaylist?.id) {
      return;
    }

    if (this.segmentPlayers) {
      for (const segmentPlayer of this.segmentPlayers as SegmentPlayer[]) {
        segmentPlayer.unload();
      }
    }
    this._playlist = newPlaylist;
    this.currentSegmentIndex = 0;
    this.segmentPlayers = this.buildSegmentPlayers(newPlaylist.segments);
    this.firePlaylistEvent(PlaylistEventType.PlaylistChanged);
    this.setPlaybackState(PlaybackState.None);
  }

  addEventListeners(eventTypes: PlaylistEventType[], callback: PlaylistEventHandler) {
    for (const eventType of eventTypes) {
      this.addEventListener(eventType, callback);
    }
  }

  addEventListener(eventType: PlaylistEventType, callback: PlaylistEventHandler) {
    if (!this.listeners.get(eventType)) {
      this.listeners.set(eventType, new Set());
    }
    this.listeners.get(eventType)?.add(callback);
  }

  removeEventListener(eventType: PlaylistEventType, callback: PlaylistEventHandler) {
    this.listeners.get(eventType)?.delete(callback);
  }

  removeEventListeners(eventTypes: PlaylistEventType[], callback: PlaylistEventHandler) {
    for (const eventType of eventTypes) {
      this.removeEventListener(eventType, callback);
    }
  }

  /**
   * Only intended for unit tests. Ensures that all listeners are removed and returns diagnostic info if not
   *
   * @returns A string that will be empty if all listeners are cleared, otherwise will contain debug info
   */
  _clearListeners(): string {
    let result = '';
    this.listeners.forEach((listeners, eventType) => {
      if (listeners.size > 0) {
        result += `Listeners not cleared for '${eventType}', count=${listeners.size}\n`;
      }
    });
    this.listeners.clear();
    return result;
  }

  preloadSonics(): void {
    if (!this.sonicPlayers) {
      // for debugging
      console.error('PlaylistPlayer.preloadSonics called before sonics were configured');
      return;
    }
    for (const sonic in this.sonicPlayers) {
      const sonicPlayer = this.sonicPlayers[sonic as unknown as Sonic];
      sonicPlayer.loadAudio();
    }
  }
}

export default new PlaylistPlayer();
