export const isBrowser = () => typeof window !== 'undefined';

type ParseDateTime = {
  date: string | Date;
  dateStyle?: 'full' | 'long' | 'medium' | 'short' | undefined;
  timeStyle?: 'full' | 'long' | 'medium' | 'short' | undefined;
};

export const parseDateToLocal = ({ date, dateStyle, timeStyle }: ParseDateTime): string => {
  const dateObj = new Date(date);
  return new Intl.DateTimeFormat('en-US', { dateStyle, timeStyle }).format(dateObj);
};
