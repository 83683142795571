import { datadogRum } from '@datadog/browser-rum';
import { isBlank } from './util/strings';

const isDatadogEnabled = process.env.NEXT_PUBLIC_DATADOG_RUM_ENABLED === 'true';
const DATADOG_ENVIRONMENT = process.env.NEXT_PUBLIC_VERCEL_ENV || 'dev';
const DATADOG_APP_ID = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
const DATADOG_CLIENT_TOKEN = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
const DATADOG_SERVICE = process.env.NEXT_PUBLIC_DATADOG_SERVICE;
const DATADOG_CONTEXT_COMMIT_SHA = 'logger.commit_sha';

export const initializeDataDog = () => {
  const shouldInitializeDatadogRum: boolean =
    datadogRum.getInitConfiguration() === undefined &&
    isDatadogEnabled &&
    !isBlank(DATADOG_APP_ID) &&
    !isBlank(DATADOG_CLIENT_TOKEN);

  if (shouldInitializeDatadogRum) {
    datadogRum.init({
      applicationId: DATADOG_APP_ID!,
      clientToken: DATADOG_CLIENT_TOKEN!,
      service: DATADOG_SERVICE,
      env: DATADOG_ENVIRONMENT,
      trackInteractions: true,
      site: 'datadoghq.com',
      sampleRate: 100,
      defaultPrivacyLevel: 'allow',
    });

    datadogRum.startSessionReplayRecording();
    datadogRum.addRumGlobalContext(DATADOG_CONTEXT_COMMIT_SHA, process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA);
  }
};
