import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useUser } from '../lib/hooks/user';
import classNames from 'classnames';
import JamLogo from './JamLogo';
import useHasMounted from '../lib/hooks/useHasMounted';

interface NavigationProps {
  name: string;
  href: string;
}

type Props = {
  hideAllLinks?: boolean;
};

const DESKTOP_NAVBAR_HEIGHT = 80;

const NavBarDesktop: FC<Props> = ({ hideAllLinks }) => {
  const router = useRouter();
  const hasMounted = useHasMounted();
  const { data: user, isValidating: isUserValidating } = useUser();
  const prevScrollPos = useRef(0);
  const [visible, setVisible] = useState(true);
  const [showShadow, setShowShadow] = useState(false);
  const [hoveredNavItem, setHoveredNavItem] = useState(-1);
  const [isHoveringOverNav, setIsHoveringOverNav] = useState(false);

  const navigation: NavigationProps[] = [
    { name: 'Listen', href: '/listen' },
    { name: 'Discover', href: '/discover' },
    { name: 'Invite', href: '/me/invite' },
    { name: 'Profile', href: `/@${user?.handle}` },
    { name: 'Settings', href: '/me' },
  ];

  const handleScroll = useCallback(() => {
    const showShadowScrollPosition = DESKTOP_NAVBAR_HEIGHT;
    const currentScrollPos = window.scrollY;
    setVisible(prevScrollPos.current > currentScrollPos);
    setShowShadow(
      (currentScrollPos > showShadowScrollPosition || prevScrollPos.current > currentScrollPos) && currentScrollPos > 0,
    );
    prevScrollPos.current = currentScrollPos;
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const showDot = useCallback((href: string): boolean => href === router.pathname, [router.pathname]);

  return (
    <nav
      className={classNames([
        'sticky z-50 flex h-16 w-full items-center justify-end bg-white duration-500 ease-out dark:bg-black-0 sm:h-20 sm:justify-center',
        { 'shadow-md': showShadow && visible },
        { 'top-0': visible },
        { '-top-20': !visible },
        { 'hidden sm:flex': !!user },
      ])}
    >
      {hasMounted &&
        !isUserValidating &&
        (!!user && !hideAllLinks ? (
          <>
            <Link href="/" className="absolute left-0 w-fit">
              <JamLogo svgClass="ml-6 se:ml-4 w-[68px] h-8" />
            </Link>

            <div
              className="mr-6 flex gap-2 text-center text-plum-1 hover:text-plum-3 dark:text-white se:mx-4 sm:mr-0 sm:gap-3"
              onMouseOver={() => setIsHoveringOverNav(true)}
              onFocus={() => setIsHoveringOverNav(true)}
              onMouseLeave={() => setIsHoveringOverNav(false)}
            >
              {navigation.map((nav, i) => (
                <Link href={nav.href} key={i} className={'hover:text-plum-1'}>
                  <div
                    className={classNames(['font-sans text-sm font-bold leading-compact hover:text-plum-1 sm:mt-3'], {
                      'sm:mb-3': !showDot(nav.href),
                    })}
                    onMouseOver={() => setHoveredNavItem(i)}
                    onFocus={() => setHoveredNavItem(i)}
                    onMouseLeave={() => setHoveredNavItem(-1)}
                  >
                    {nav.name}
                    {showDot(nav.href) && (
                      <div
                        className={`mx-auto mt-0 hidden h-1 w-1 rounded-full bg-plum-1 dark:bg-white  sm:mt-2 sm:block ${
                          hoveredNavItem === i && `dark:bg-plum-1`
                        } ${isHoveringOverNav && hoveredNavItem !== i && `bg-plum-3 dark:bg-plum-3`}`}
                      />
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="flex h-full w-full items-center justify-center">
              <Link href="/">
                <JamLogo svgClass="w-[68px] h-8" />
              </Link>
              {!hideAllLinks && (
                <Link
                  href="/login"
                  className="absolute right-0 mr-6 text-right text-sm font-bold leading-compact text-plum-1 dark:text-dragonfruit-1 sm:mr-8"
                >
                  Sign in
                </Link>
              )}
            </div>
          </>
        ))}
    </nav>
  );
};
export default NavBarDesktop;
