import { Player } from '../../types';
import { debug } from './debug';

export type HtmlAudioPlayerOptions = {
  audioSrc: string;
  speed: number;
  expectedDuration?: number;
  onPlay?: () => void;
  onPause?: () => void;
  onEnd?: () => void;
  onLoad?: () => void;
  onError?: (error: unknown) => void;
};

// MediaSession is quirky. I've consistently observed that if I reset with a valid audio source then it screws up the MediaSession. Setting to a valid format and invalid
// data throws an error, but it results in the right browser behavior. And it keeps the Audio element unlocked so that we can re-use it if we replay by restoring
// a valid source.
const EMPTY_SOUND = 'data:audio/wav;base64,BOGUS';

/**
 * Manages loading and playback of a remote audio source. It lazily loads the audio source, either just-in-time when
 * `play()` is called or when `loadAudio()` is called.
 */
export class HtmlAudioPlayer implements Player {
  private audioSrc: string;
  private currentSpeed: number;
  private expectedDuration?: number;
  private onPlay?: () => void;
  private onPause?: () => void;
  private onEnd?: () => void;
  private onLoad?: () => void;
  private onError?: (error: unknown) => void;
  private errorEvent?: Event;
  private audioElement?: HTMLAudioElement;
  private audioEventListeners: Map<string, EventListener> = new Map();

  constructor({ audioSrc, speed, expectedDuration, onPlay, onPause, onEnd, onError, onLoad }: HtmlAudioPlayerOptions) {
    this.audioSrc = audioSrc;
    this.currentSpeed = speed;
    this.expectedDuration = expectedDuration;
    this.onPlay = onPlay;
    this.onPause = onPause;
    this.onEnd = onEnd;
    this.onError = onError;
    this.onLoad = onLoad;
  }

  toString() {
    return `HtmlAudioPlayer[${this.audioSrc}]`;
  }

  private debug(message: string) {
    debug(`${this.toString()}) ${message}`);
  }

  private addAudioEventListener(eventName: string, handler: EventListener) {
    const decoratedHandler = (event: Event) => {
      this.debug(event.type);
      handler(event);
    };

    this.audioElement!.addEventListener(eventName, decoratedHandler);
    this.audioEventListeners.set(eventName, decoratedHandler);
  }

  private createAudioElement() {
    this.debug('Creating audio');
    if (!this.audioElement) {
      this.audioElement = new Audio();
    }
    this.audioElement.src = this.audioSrc;
    this.audioElement.playbackRate = this.speed;

    this.addAudioEventListener('play', () => {
      this.onPlay?.();
    });
    this.addAudioEventListener('playing', () => {
      this.onPlay?.();
    });
    this.addAudioEventListener('canplaythrough', () => {
      this.onLoad?.();
    });
    this.addAudioEventListener('error', (event) => {
      console.error(
        `Error loading audio for ${this}: ${this.audioElement?.error?.code} ${this.audioElement?.error?.message}`,
        event,
      );

      // this isn't doing what i think it's doing. the error is on the audio element object! Deal with this as part of #838
      this.errorEvent = event as ErrorEvent;

      this.onError?.(event);
    });
    this.addAudioEventListener('ended', () => {
      this.onEnd?.();
      this.unload();
    });
    this.addAudioEventListener('pause', () => {
      this.onPause?.();
    });
  }

  get speed(): number {
    return this.currentSpeed;
  }

  /**
   * Set the playback speed. If it's playing, it will take effect immediately.
   *
   * @param newSpeed New playback speed
   */
  set speed(newSpeed: number) {
    this.currentSpeed = newSpeed;

    if (this.audioElement) {
      this.audioElement.playbackRate = newSpeed;
    }
  }

  /**
   * @returns Duration of audio in seconds. Note that if the audio isn't loaded, this will fallback to the
   *   `expectedDuration` provided when the object was constructed
   */
  get duration(): number {
    return (this.audioElement && this.audioElement.duration) || this.expectedDuration || 0;
  }

  /** @returns Current position in the audio. If it is not yet loaded or has an error, it will return 0 */
  get position(): number {
    return this.audioElement?.currentTime || 0;
  }

  /** @returns Error code or message if there was previously a playback or load error */
  get error(): unknown {
    const anyError = this.errorEvent as any;
    return anyError?.error || anyError?.message || 'unknown error';
  }

  get isPlaying(): boolean {
    return this.audioElement?.paused === false;
  }

  get timeRemaining(): number {
    return this.duration - this.position;
  }

  get percentPlayed(): number {
    return this.duration > 0 ? Math.floor(((this.duration - this.timeRemaining) / this.duration) * 100) : 0;
  }

  get isAudioLoaded(): boolean {
    return this.audioElement !== undefined && this.audioElement.readyState > 0;
  }

  /** Attempt to load the audio. If it's already in progress this is a no-op. */
  loadAudio() {
    if (!this.audioElement || this.audioElement?.src === EMPTY_SOUND) {
      this.createAudioElement();
    }
  }

  /**
   * Attempts to start playback for the audio. If it is in a state where playback may succeed, then it returns `true`.
   * If so, the client should expect to receive playback events after this is called. If it is in a known error state,
   * then it returns `false` and no playback or error events are guaranteed
   */
  play(): boolean {
    this.loadAudio();

    this.audioElement!.play();
    return true;
  }

  /** Pause this audio at its current position */
  pause() {
    this.audioElement?.pause();
  }

  /** Stop playback and reset seek to 0 */
  stop() {
    this.pause();
    this.unload();
  }

  /**
   * Seek playback to the specified position
   *
   * @param newPosition Position to seek to
   */
  seek(newPosition: number) {
    if (this.audioElement) {
      this.audioElement.currentTime = newPosition;
    }
  }

  unload() {
    if (this.audioElement) {
      this.debug('unloading');
      this.audioEventListeners.forEach((listener, event) => {
        this.audioElement!.removeEventListener(event, listener);
      });
      this.audioEventListeners.clear();
      this.audioElement.src = EMPTY_SOUND;
      delete this.errorEvent;
    }
  }
}
