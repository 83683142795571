/**
 * Checks to see if a string is present and has non-whitespace characters. A string is "blank" when it is `undefined`,
 * empty, or consists only of whitespace characters
 */
export function isBlank(str?: string): boolean {
  return str === undefined || str.trim().length === 0;
}

export function stripMarkdown(markdown: string) {
  return (
    markdown
      // Remove headers
      .replace(/^(#{1,6}|\d+)\s+(.+)$/gm, '$2')
      // Remove HTML tags
      .replace(/<[^>]*>/g, '')
      // Remove setext-style headers
      .replace(/^(.+)\n[=-]*$/gm, '$1')
      // Remove footnotes but keep the footnote text
      .replace(/^\[\^(.+)\]: (.+)/gm, '$2')
      // Remove images
      .replace(/!\[(.*?)\]\((.*?)\)/g, '$1 $2')
      // Keep the text only from inline links and remove the rest
      .replace(/\[(.*?)\]\((.*?)\)/g, '$1')
      // Remove blockquotes
      .replace(/^\s{0,3}> (.*)$/gm, '$1')
      // Remove codeblocks
      .replace(/```(.*?)```/gs, '$1')
      // Remove inline code
      .replace(/`(.*?)`/g, '$1')
      // Remove bold/italic/underscore
      .replace(/(\*\*|__)(.*?)(\*\*|__)/g, '$2')
      .replace(/(\*|_)(.*?)(\*|_)/g, '$2')
      // Remove strikethroughs
      .replace(/~~(.*?)~~/g, '$1')
      // Replace two or more newlines with exactly two? Not entirely sure this belongs here...
      .replace(/\n{2,}/g, '\n\n')
  );
}

/**
 * Takes a string and returns the first `n` words of that string
 *
 * @param str The string to take words from
 * @param n The number of words to take
 * @returns The first `n` words of the string
 */
export function takeNWords(str: string, n: number) {
  return str.split(/\s+/).slice(0, n).join(' ');
}

/**
 * Counts the number of words in a string and returns the number
 *
 * @param str
 * @returns
 */
export function wordCount(str: string) {
  return str.trim() === '' ? 0 : str.split(/\s+/).length;
}
