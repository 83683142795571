import useSWR, { KeyedMutator, mutate, SWRResponse } from 'swr';
import { deleteAccessToken, hasAccessToken, put } from '../api-client';
import { User, PaginatedData, UserAggregation, UserSegmentEvent, Segment } from '../../types';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { loginWithSignedRequest } from '../auth';
import { isSignedUrl, stripUrlSignature } from '../util/urls';
import useSWRInfinite from 'swr/infinite';
import { AxiosError } from 'axios';

export type UserSettingsKey = 'sms.optOutStatus';
type UserSettings = Partial<Record<UserSettingsKey, string>>;

export function useUser(): SWRResponse<User> {
  const loggedIn = hasAccessToken();

  return useSWR<User>(loggedIn ? '/api/users/me' : null);
}

export function useUserByHandle(handle: string): SWRResponse<User> {
  return useSWR<User>(handle ? `/api/users/${handle}` : null);
}

export function useUserSettings(...keys: UserSettingsKey[]): SWRResponse<UserSettings> {
  const loggedIn = hasAccessToken();

  return useSWR<UserSettings>(loggedIn ? [`/api/users/me/user-settings`, { key: keys, apiVersion: '2' }] : undefined);
}

export async function updateUserSetting(key: UserSettingsKey, value: string): Promise<void> {
  await put(`/api/users/me/user-settings`, { key, value });
  mutate([`/api/users/me/user-settings`, { key: [key], apiVersion: '2' }]);
}

export function useUserAggregation(): (UserAggregation & { duration?: number }) | undefined {
  const loggedIn = hasAccessToken();

  const { data, error, isValidating } = useSWR<UserAggregation & { duration?: number }, Error>(
    loggedIn ? `/api/users/me/user-aggregation` : undefined,
  );
  return error ? undefined : data;
}

export function useAuthenticatedUser(): SWRResponse<User> {
  const router = useRouter();

  const hasToken = hasAccessToken();
  const isLoggingIn = useRef(false);

  const {
    data: userData,
    error: userError,
    isValidating: userIsValidating,
    mutate: userMutate,
    isLoading: userIsLoading,
  } = useSWR<User>(hasToken ? '/api/users/me' : null);

  useEffect(() => {
    async function login() {
      await loginWithSignedRequest(window.location.href);
      isLoggingIn.current = false;
      const strippedPath = stripUrlSignature(router.pathname, router.query);
      router.replace(strippedPath, undefined, { shallow: true });
    }
    if (
      !userData &&
      !userError &&
      !userIsValidating &&
      !isLoggingIn.current &&
      router.isReady &&
      isSignedUrl(router.query)
    ) {
      isLoggingIn.current = true;
      login();
    }
  }, [userData, userError, userIsValidating, hasToken, router]);

  if (userError) {
    if ((userError as AxiosError).response?.status === 401) {
      deleteAccessToken();
      window.location.href = '/';
      return {
        data: userData,
        isValidating: false,
        mutate: userMutate,
        isLoading: false,
        error: undefined,
      };
    } else {
      return { data: userData, isValidating: false, error: userError, mutate: mutate, isLoading: false };
    }
  }

  const isPossiblySignedUrl = !router.isReady || isSignedUrl(router.query);
  const isValidatingUser = userIsValidating || isLoggingIn.current || isPossiblySignedUrl;

  return {
    data: userData,
    isValidating: isValidatingUser,
    mutate: userMutate,
    isLoading: userIsLoading,
    error: undefined,
  };
}

type SegmentEventInfinitePagination = {
  pages: PaginatedData<UserSegmentEvent>[] | undefined;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  currentCount: number;
  totalCount: number;
  mutate: KeyedMutator<PaginatedData<UserSegmentEvent>[]>;
};

export function useUserSegmentEventPages(limit: number): SegmentEventInfinitePagination {
  const loggedIn = hasAccessToken();

  const getKey = (pageIndex: number, previousPageData: PaginatedData<UserSegmentEvent>) => {
    if (previousPageData && !previousPageData.data.length) return null; // reached the end
    return loggedIn ? [`/api/users/me/user-segment-events`, { offset: pageIndex * limit, limit: limit }] : null;
  };

  const {
    data: pages,
    size: pageNumber,
    setSize: setPageNumber,
    mutate,
  } = useSWRInfinite<PaginatedData<UserSegmentEvent>>(getKey);
  let currentCount = 0;
  let totalCount = 0;
  if (pages?.length) {
    totalCount = pages[0].totalCount;
  }
  pages?.forEach((page) => (currentCount += page.data.length));

  return { pages, pageNumber, setPageNumber, currentCount, totalCount, mutate };
}

export function useLikedSegmentsByUserId(userId?: string): SWRResponse<{ rating: string; segment: Segment }[]> {
  return useSWR<{ rating: string; segment: Segment }[], Error>(userId ? `/api/users/${userId}/liked-segments` : null);
}
