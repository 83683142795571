import type { FC } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import React from 'react';
import NavBarDesktop from './NavBarDesktop';

type props = { errorMessage: string };

const httpStatusCodes = {
  '400': 'Bad Request',
  '401': 'Unauthorized',
  '402': 'Payment Required',
  '403': 'Forbidden',
  '404': 'Not Found',
  '405': 'Method Not Allowed',
  '406': 'Not Acceptable',
  '407': 'Proxy Authentication Required',
  '408': 'Request Timeout',
  '409': 'Conflict',
  '410': 'Gone',
  '411': 'Length Required',
  '412': 'Precondition Failed',
  '413': 'Payload Too Large',
  '414': 'URI Too Long',
  '415': 'Unsupported Media Type',
  '416': 'Range Not Satisfiable',
  '417': 'Expectation Failed',
  '418': "I'm a teapot",
  '421': 'Misdirected Request',
  '422': 'Unprocessable Entity',
  '423': 'Locked',
  '424': 'Failed Dependency',
  '425': 'Unordered Collection',
  '426': 'Upgrade Required',
  '428': 'Precondition Required',
  '429': 'Too Many Requests',
  '431': 'Request Header Fields Too Large',

  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
  '505': 'HTTP Version Not Supported',
  '506': 'Variant Also Negotiates',
  '507': 'Insufficient Storage',
  '508': 'Loop Detected',
  '509': 'Bandwidth Limit Exceeded',
  '510': 'Not Extended',
  '511': 'Network Authentication Required',
};

const ErrorComponent: FC<props> = ({ errorMessage }) => {
  let errorCode = 500;

  for (let index = 0; index < Object.keys(httpStatusCodes).length; index++) {
    const code = Object.keys(httpStatusCodes)[index];
    if (errorMessage?.includes(code)) {
      errorCode = Number(code);
      break;
    }
  }

  return (
    <>
      <Head>
        <title>Error | Jam</title>
        <style>{`#__next { height: 100% }`}</style>
      </Head>
      <div className="hidden sm:flex">
        <NavBarDesktop />
      </div>
      <div className="flex min-h-full flex-col items-center justify-center bg-white">
        {errorCode && <p className="text-8xl">{errorCode}</p>}
        {errorMessage && <p className="mt-2 text-center text-3xl ">{errorMessage}</p>}
        <Link href="/">
          <button className="mt-10 rounded-xl border-2 bg-plum-1 p-3 font-sans text-white "> ⟵ Back to Home</button>
        </Link>
      </div>
    </>
  );
};
export default ErrorComponent;
