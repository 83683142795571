import { AudioError, Player } from '../../types';
import { HtmlAudioPlayer } from './HtmlAudioPlayer';
import { PlaylistSegment } from './PlaylistSegment';

type SegmentPlayerOptions = {
  segment: PlaylistSegment;
  speed: number;
  onIntroEnd?: () => void;
  onIntroError?: (error: AudioError) => void;
  onSegmentEnd?: () => void;
  onSegmentError?: (error: AudioError) => void;
  onSegmentLoad?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
};

export class SegmentPlayer implements Player {
  private introAudio?: HtmlAudioPlayer;
  private segmentAudio?: HtmlAudioPlayer;
  private segment: PlaylistSegment;
  private currentSpeed: number;
  private onPlay?: () => void;
  private onPause?: () => void;
  private onIntroEnd?: () => void;
  private onIntroError?: (error: AudioError) => void;
  private onSegmentEnd?: () => void;
  private onSegmentError?: (error: AudioError) => void;
  private onSegmentLoad?: () => void;

  constructor({
    segment,
    speed,
    onPlay,
    onPause,
    onIntroEnd,
    onIntroError,
    onSegmentEnd,
    onSegmentError,
    onSegmentLoad,
  }: SegmentPlayerOptions) {
    this.segment = segment;
    this.onPlay = onPlay;
    this.onPause = onPause;
    this.onIntroEnd = onIntroEnd;
    this.onIntroError = onIntroError;
    this.onSegmentEnd = onSegmentEnd;
    this.onSegmentError = onSegmentError;
    this.onSegmentLoad = onSegmentLoad;
    this.currentSpeed = speed;

    if (segment.intro) {
      this.introAudio = new HtmlAudioPlayer({
        audioSrc: segment.intro.playbackUrl,
        speed,
        onPlay: () => {
          this.onPlay && this.onPlay();
        },
        onPause: () => {
          this.onPause && this.onPause();
        },
        onEnd: () => {
          this.onIntroEnd && this.onIntroEnd();
          this.safeSegmentAudio().play();
        },
        onError: (error: AudioError) => {
          this.onIntroError && this.onIntroError(error);
        },
      });
    }
    this.segmentAudio = new HtmlAudioPlayer({
      audioSrc: segment.playbackUrl,
      speed,
      onPlay: () => {
        this.onPlay && this.onPlay();
      },
      onPause: () => {
        this.onPause && this.onPause();
      },
      onEnd: () => {
        this.onSegmentEnd && this.onSegmentEnd();
      },
      onLoad: () => {
        this.onSegmentLoad && this.onSegmentLoad();
      },
      onError: (error: AudioError) => {
        this.onSegmentError && this.onSegmentError(error);
      },
    });
  }

  private safeSegmentAudio(): HtmlAudioPlayer {
    // Meant to root out bugs and prevent unpredictable behavior
    if (!this.segmentAudio) {
      throw new Error('Operation not permitted after segment audio is unloaded');
    }
    return this.segmentAudio;
  }

  get speed(): number {
    return this.currentSpeed;
  }

  /**
   * Set the playback speed. If it's playing, it will take effect immediately.
   *
   * @param newSpeed New playback speed
   */
  set speed(newSpeed: number) {
    this.currentSpeed = newSpeed;
    if (this.introAudio) {
      this.introAudio.speed = newSpeed;
    }
    this.safeSegmentAudio().speed = newSpeed;
  }

  get duration(): number {
    return this.safeSegmentAudio().duration ? this.safeSegmentAudio().duration : this.segment.duration;
  }

  get position(): number {
    return this.safeSegmentAudio().position;
  }

  get error(): AudioError {
    return this.introAudio?.error || this.safeSegmentAudio().error;
  }

  get isPlaying(): boolean {
    return this.introAudio?.isPlaying || this.safeSegmentAudio().isPlaying;
  }

  get percentPlayed(): number {
    return this.safeSegmentAudio().percentPlayed;
  }

  get timeRemaining(): number {
    return this.safeSegmentAudio().isAudioLoaded ? this.safeSegmentAudio().timeRemaining : this.segment.duration;
  }

  get isAudioLoaded(): boolean {
    return !!this.introAudio?.isAudioLoaded && this.safeSegmentAudio().isAudioLoaded;
  }

  loadAudio() {
    if (this.introAudio) {
      this.introAudio.loadAudio();
    }
    this.safeSegmentAudio().loadAudio();
  }

  stop() {
    if (this.introAudio?.isPlaying) {
      this.introAudio?.stop();
    } else {
      this.safeSegmentAudio().stop();
    }
  }

  seek(newPosition: number) {
    if (this.introAudio?.isPlaying) {
      this.introAudio.seek(newPosition);
    } else {
      this.safeSegmentAudio().seek(newPosition);
    }
  }

  play(withIntro: boolean = true): boolean {
    return withIntro && this.introAudio && this.safeSegmentAudio().position === 0
      ? this.introAudio.play()
      : this.safeSegmentAudio().play();
  }

  pause() {
    if (this.introAudio?.isPlaying) {
      this.introAudio.pause();
    } else {
      this.safeSegmentAudio().pause();
    }
  }

  unload() {
    this.safeSegmentAudio().unload();
    this.segmentAudio = undefined;
    this.introAudio?.unload();
    this.introAudio = undefined;
  }
}
