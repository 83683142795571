import { HtmlAudioPlayer, HtmlAudioPlayerOptions } from './HtmlAudioPlayer';

type SonicPlayerOptions = Omit<HtmlAudioPlayerOptions, 'speed'>;

const SONIC_SPEED: number = 1;

export class SonicPlayer extends HtmlAudioPlayer {
  constructor({ audioSrc, onPlay, onPause, onEnd, onError, onLoad }: SonicPlayerOptions) {
    super({
      audioSrc,
      speed: SONIC_SPEED,
      onEnd,
      onError,
      onLoad,
      onPause,
      onPlay,
    });
  }
}
