import { TrackingData, TrackingEvent } from '../types';
import { post } from './api-client';

export async function trackUserEvent(event: TrackingEvent, eventData: TrackingData = {}) {
  const decoratedData = {
    url: window.location.pathname,
    ...eventData,
  };
  const trackingData = {
    event,
    eventData: decoratedData,
    clientTs: new Date(),
  };

  try {
    await post('/api/tracking-events', trackingData);
  } catch (error) {
    console.error(`Error posting tracking event`, trackingData, error);
  }
}
