import axios, { AxiosRequestHeaders } from 'axios';
import { isBrowser } from './util';
import Cookies from 'universal-cookie';

const apiRoot = process.env.NEXT_PUBLIC_API_BASE_URL;
if (!apiRoot) {
  throw new Error('Missing env variable NEXT_PUBLIC_API_BASE_URL');
}

const axiosInstance = axios.create({
  baseURL: apiRoot,
});

const accessTokenStorageKey = 'accessToken';
const accessTokenHeader = 'jam-access-token';

const buildAccessTokenHeader = (token: string | undefined): AxiosRequestHeaders => {
  return token ? { [accessTokenHeader]: token } : {};
};

function readAccessToken(cookies?: string): string | undefined {
  return new Cookies(cookies).get(accessTokenStorageKey);
}

export function hasAccessToken(cookies?: string): boolean {
  const token = readAccessToken(cookies);
  return !!token && token.length > 0;
}

export function setAccessToken(jwt: string) {
  if (!isBrowser()) {
    console.warn('Access token can only be written from the browser');
    return;
  }
  // Extract the expiry from the JWT token and set the cookie expiry to match
  const [header, payload] = jwt.split('.');
  const { exp } = JSON.parse(window.atob(payload));
  const cookieExpires = new Date(exp * 1000);
  new Cookies().set(accessTokenStorageKey, jwt, { expires: cookieExpires, path: '/' });
}

export function deleteAccessToken() {
  if (!isBrowser()) {
    console.warn('Access token can only be removed from the browser');
    return;
  }
  new Cookies().set(accessTokenStorageKey, '');
  new Cookies().remove(accessTokenStorageKey);
}

export async function post<T>(path: string, data: object): Promise<T> {
  const accessToken = readAccessToken();
  const axiosResponse = await axiosInstance.post<T>(path, data, {
    headers: buildAccessTokenHeader(accessToken),
  });
  return axiosResponse.data;
}

export async function get<T>(path: string): Promise<T>;
export async function get<T>(path: string, cookies?: string): Promise<T>;
export async function get<T>(path: string, cookies?: string, params?: object): Promise<T>;
export async function get<T>(path: string, params?: object): Promise<T>;
export async function get<T>(path: string, cookiesOrParams?: object | string, params?: object): Promise<T> {
  const cookies = typeof cookiesOrParams === 'string' ? cookiesOrParams : undefined;
  const accessToken = readAccessToken(cookies);
  const parsedParams = typeof cookiesOrParams === 'object' ? cookiesOrParams : params;
  const axiosResponse = await axiosInstance.get<T>(path, {
    params: parsedParams,
    headers: buildAccessTokenHeader(accessToken),
  });

  return axiosResponse.data;
}

export async function deleteReq<T>(path: string, cookiesOrParams?: object | string, params?: object): Promise<T> {
  const cookies = typeof cookiesOrParams === 'string' ? cookiesOrParams : undefined;
  const accessToken = readAccessToken(cookies);
  const parsedParams = typeof cookiesOrParams === 'object' ? cookiesOrParams : params;

  const axiosResponse = await axiosInstance.delete<T>(path, {
    params: parsedParams,
    headers: buildAccessTokenHeader(accessToken),
  });
  return axiosResponse.data;
}

/**
 * PUT request with body This request is used to update a resource
 *
 * @param path - The path to the resource
 * @param data - The data to be sent in the body of the request
 * @param cookiesOrParams - The cookies/params to be sent in the request
 */

export async function put<T>(
  path: string,
  body?: object,
  cookiesOrParams?: object | string,
  params?: object,
): Promise<T> {
  const cookies = typeof cookiesOrParams === 'string' ? cookiesOrParams : undefined;
  const accessToken = readAccessToken(cookies);
  const parsedParams = typeof cookiesOrParams === 'object' ? cookiesOrParams : params;

  const axiosResponse = await axiosInstance.put<T>(path, body, {
    params: parsedParams,
    headers: buildAccessTokenHeader(accessToken),
  });
  return axiosResponse.data;
}
