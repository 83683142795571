export enum LegalInfoOption {
  Always = 'Always',
  Never = 'Never',
  WhenLoggedOut = 'WhenLoggedOut',
}

export enum UserRequirement {
  AnyUser = 'User',
  OnboardedUser = 'OnboardedUser',
  Admin = 'Admin',
  None = 'None',
}

export type OGImage = {
  location: string;
  mimeType?: 'image/png';
  width?: number;
  height?: number;
  altText: string;
};
