import Head from 'next/head';
import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';
import { useAuthenticatedUser } from '../lib/hooks/user';
import { trackUserEvent } from '../lib/tracking';
import { UserRequirement } from '../types';
import JamLogo from './JamLogo';
import Link from 'next/link';

type PageBoundaryProps = {
  children: React.ReactNode;
  userReq: UserRequirement;
};

const isShutdownMode = process.env.NEXT_PUBLIC_SHUTDOWN_MODE === 'true';

function Loading() {
  return (
    <>
      <Head>
        <title>Loading | Jam</title>
        <style>{`#__next { height: 100% }`}</style>
      </Head>
      <main
        data-testid="page-boundary-loading"
        className="flex min-h-full w-full flex-col justify-center dark:bg-black-0"
      >
        <JamLogo svgClass="mx-auto animate-pulse w-32 h-[52px]" />
      </main>
    </>
  );
}

function ShutdownNotice() {
  return (
    <>
      <Head>
        <title>Notice | Jam</title>
        <style>{`#__next { height: 100% }`}</style>
      </Head>
      <div className="flex min-h-full flex-col justify-between dark:bg-black-0">
        <div className="flex h-16 w-full items-center justify-center">
          <JamLogo svgClass="w-[68px] h-8" />
        </div>
        <main className="mx-auto flex w-full grow flex-col px-6 pb-20 pt-4 sm:w-[600px] sm:px-0">
          <div className="flex flex-col items-center gap-5 dark:text-white">
            <p className="text-center text-lg font-bold">As of July 15, Jam is no longer active.</p>
            <p className="text-center text-lg">
              If you need assistance migrating your podcast playlist to another service, we’re here to help.
            </p>
            <p className="text-center text-lg">
              Contact us at{' '}
              <Link href="mailto:support@jam.ai" className="text-plum-1 dark:text-dragonfruit-1">
                support@jam.ai
              </Link>
              .
            </p>
          </div>
        </main>
      </div>
    </>
  );
}

const PageBoundary: FC<PageBoundaryProps> = ({ children, userReq }) => {
  const { data: user, isValidating: isUserValidating } = useAuthenticatedUser();
  const [isPageShowing, setIsPageShowing] = useState(userReq === UserRequirement.None);

  const router = useRouter();

  useEffect(() => {
    if (!isShutdownMode) {
      switch (userReq) {
        case UserRequirement.None:
          setIsPageShowing(true);
          break;
        case UserRequirement.AnyUser:
          if (user) {
            setIsPageShowing(true);
          } else if (!isUserValidating) {
            setIsPageShowing(false);
            router.push('/login');
          }
          break;
        case UserRequirement.OnboardedUser:
          if (user && user.has_onboarded) {
            setIsPageShowing(true);
          } else if (user && !user.has_onboarded) {
            setIsPageShowing(false);
            router.push('/me/onboarding');
          } else if (!isUserValidating) {
            setIsPageShowing(false);
            router.push('/login');
          }
          break;
        case UserRequirement.Admin:
          if (user && user.is_admin) {
            setIsPageShowing(true);
          } else if (!isUserValidating) {
            throw new Error('404');
          }
          break;
        default:
          throw new Error(`Unsupported UserRequirement: ${userReq}`);
      }
    }
  }, [userReq, isUserValidating, router, user]);

  useEffect(() => {
    if (router.isReady && isPageShowing) {
      trackUserEvent('pageLoad');
    }
  }, [isPageShowing, router]);

  if (isShutdownMode) {
    return <ShutdownNotice />;
  } else if (isPageShowing) {
    return children;
  } else {
    return <Loading />;
  }
};

export default PageBoundary;
