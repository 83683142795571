import { SvgFC } from '../types';

const JamLogo: SvgFC = ({ svgClass, pathClass }) => (
  <svg role="img" viewBox="0 0 69 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={svgClass}>
    <path
      d="M65.0946 0H3.90542C1.74852 0 0 1.71139 0 3.82251V28.1775C0 30.2886 1.74852 32 3.90542 32H65.0946C67.2515 32 69 30.2886 69 28.1775V3.82251C69 1.71139 67.2515 0 65.0946 0Z"
      fill="url(#paint0_linear_5764_21612)"
      className={pathClass}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.7976 22.6197C28.7976 23.0047 28.6 23.2939 28.2066 23.4873C27.8132 23.6807 26.8289 23.7765 25.2553 23.7765H24.0751C23.2492 23.7539 22.8344 23.4873 22.8344 22.9786C22.8344 22.8705 22.854 22.7521 22.8932 22.6197L27.3682 8.45164C27.5338 7.88192 28.4024 7.59619 29.9778 7.59619H32.3399C33.9135 7.59619 34.7839 7.88192 34.9494 8.45164L39.4245 22.6197C39.4637 22.7503 39.4832 22.8705 39.4832 22.9786C39.4832 23.4873 39.0703 23.7521 38.2443 23.7765H37.0641C35.4888 23.7765 34.5133 23.6807 34.136 23.4873C33.7817 23.3026 33.6037 23.0361 33.6037 22.6893V22.6197H28.7976ZM31.1597 14.6349L30.1326 17.9975H32.1868L31.1597 14.6349ZM46.1709 15.6855V22.6197C46.1709 23.3897 45.7775 23.7748 44.9907 23.7748H41.4484C40.6616 23.7748 40.2682 23.3897 40.2682 22.6197V8.75131C40.2682 7.98123 40.6616 7.59619 41.4484 7.59619H44.9676C45.7704 7.59619 46.3293 7.8314 46.6444 8.30181L49.715 12.7968L52.7375 8.3593C53.0846 7.85056 53.6417 7.59619 54.4143 7.59619H57.9797C58.7665 7.59619 59.1599 7.98123 59.1599 8.75131V22.6197C59.1599 23.3897 58.7665 23.7748 57.9797 23.7748H54.4374C53.6506 23.7748 53.2573 23.3897 53.2573 22.6197V15.6855C51.6837 18.4278 50.6993 19.9453 50.3059 20.238C50.1084 20.3843 49.9125 20.4575 49.715 20.4575C49.5174 20.4575 49.2717 20.3478 48.9762 20.1282C48.6808 19.9087 47.7462 18.4278 46.1727 15.6855H46.1709ZM18.97 7.7269H22.5123V7.72516C23.2759 7.72516 23.6693 8.1102 23.6924 8.88027L23.6622 14.9538L23.6586 15.8144C23.6586 19.8182 22.361 22.3322 19.771 23.3636C19.7532 23.3706 19.7349 23.3785 19.7167 23.3863C19.6984 23.3941 19.6802 23.402 19.6624 23.4089C15.6591 24.9317 12.6882 23.909 10.755 20.3304L10.2032 19.3094C9.83475 18.6299 9.99852 18.109 10.6945 17.7484L13.8256 16.1281C14.5198 15.7674 15.0521 15.9277 15.4205 16.6089L15.9724 17.6299C16.1254 17.9121 16.364 18.0707 16.679 18.1195C17.4178 18.0742 17.7898 17.3529 17.7898 15.9434V8.88202C17.7898 8.11194 18.1832 7.7269 18.97 7.7269Z"
      fill="white"
      className={pathClass}
    />
    <defs>
      <linearGradient
        id="paint0_linear_5764_21612"
        x1="0"
        y1="16.0001"
        x2="69"
        y2="16.0001"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6900BC" />
        <stop offset="1" stopColor="#DA01BE" />
      </linearGradient>
    </defs>
  </svg>
);

export default JamLogo;
