import { ParsedUrlQuery, stringify } from 'querystring';

const AUTH_USER_QUERY_KEY = '_au';
const SIGNATURE_QUERY_KEY = '_s';
const EXPIRY_KEY = '_e';

/**
 * Removes the protocol and/or the leading www. if present in the URL. For example,
 * https://www.linkedin.com/company/listentojam is replaced with linkedin.com/company/listentojam
 *
 * @param {string} url
 * @returns URL without protocol or leading www.
 */
export function prettyUrl(url: string) {
  // Remove protocol, 'www.', query parameters, anchors and trailing slash
  return url
    .replace(/^((\w+:)\/\/)?(www.)?/, '') // remove protocol and 'www.'
    .split(/[?#]/)[0] // split by '?' and '#' to remove query params and anchors
    .replace(/\/$/, ''); // remove trailing slash
}

export function isSignedUrl(query: ParsedUrlQuery) {
  return query[SIGNATURE_QUERY_KEY] !== undefined && query[SIGNATURE_QUERY_KEY] !== '';
}

export function stripUrlSignature(path: string, query: ParsedUrlQuery) {
  const strippedQuery = { ...query };
  delete strippedQuery[AUTH_USER_QUERY_KEY];
  delete strippedQuery[SIGNATURE_QUERY_KEY];
  delete strippedQuery[EXPIRY_KEY];

  let strippedPath = path;
  const queryString = stringify(strippedQuery);
  if (queryString.length > 0) {
    strippedPath += '?';
    strippedPath += queryString;
  }
  return strippedPath;
}

/** Creates a relative Url from the path and query object. Ensures that the query is URI encoded */
export function relativeUrl({ path, query }: { path: string; query?: Record<string, string> }): string {
  let result = path;
  if (query) {
    const searchParams = new URLSearchParams(query);
    result += `?${searchParams.toString()}`;
  }
  return result;
}

/** Removes all parts of a pathname after and including `?` */
export function pathOnly({ path }: { path: string }): string {
  return path.split('?')[0];
}

export function encodeObject(obj: any) {
  return encodeURIComponent(JSON.stringify(obj));
}

export function decodeObject(encodedObject: string): any {
  return JSON.parse(decodeURIComponent(encodedObject));
}

export function encodeUriObject(obj: any) {
  return encodeURIComponent(JSON.stringify(obj));
}

export function decodeUriObject(encodedObject: string): any {
  return JSON.parse(decodeURIComponent(encodedObject));
}
