import { post, setAccessToken } from './api-client';

/**
 * Requests a one-time password for the given phone number.
 *
 * @param phoneNumber Phone number to send OTP to
 * @returns True iff the request was accepted
 */
export async function requestLoginOtp(phoneNumber: string): Promise<boolean> {
  try {
    await post('/api/auth/otp-request', { phone: phoneNumber });
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Logs a user in using a phone number and one-time password
 *
 * @param phone Phone number
 * @param code One-time password
 * @returns True iff the user was logged in
 */
export async function loginWithOtp(phone: string, code: string): Promise<boolean> {
  try {
    const response = await post<{ token: string }>('/api/auth/access-token', {
      phone,
      code,
    });
    setAccessToken(response.token);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 * Logs a user in using a signed URL
 *
 * @param signedUrl
 * @returns True iff the user was logged in
 */
export async function loginWithSignedRequest(signedUrl: string): Promise<boolean> {
  try {
    const response = await post<{ token: string }>('/api/auth/access-token', {
      signedUrl,
    });
    setAccessToken(response.token);
    return true;
  } catch (error) {
    console.error('Error logging in with signed request', error);
    return false;
  }
}
