export enum ContributionType {
  Host = 'Host',
  CoHost = 'CoHost',
  Guest = 'Guest',
  Other = 'Other',
}

export type StreamRestrictionMode = 'None' | 'DefaultTrue' | 'DefaultFalse';

export type PodcastFunding = {
  message: string;
  url: string;
};

export type User = {
  bio?: string | null;
  contact_platform: string | null;
  created_at: Date | string | null;
  email: string | null;
  goes_by: string | null;
  handle?: string;
  has_onboarded: boolean | null;
  hidden: boolean | null;
  id: string;
  image_bucket?: string;
  image_key?: string;
  Invitation?: Invitation[];
  invited_by?: string | null;
  is_admin: boolean | null;
  link_in_bio?: string | null;
  locale: string | null;
  phone: string | null;
  status: string | null;
  StripeCustomerAccount?: StripeCustomerAccount;
  timezone: string | null;
  updated_at: Date | string | null;
  zip5?: string | null;
};

export type StripeCustomerAccount = {
  stripe_customer_id: string;
};

export type Invitation = {
  inviting_user: {
    goes_by: string | null;
  } | null;
};

export type PriceId = {
  id: string;
  price: number;
  price_type: string;
  stripe_price_id: string;
};

export type Stream = {
  StreamUser?: StreamUser[] | null;
  row_id: number;
  id: string;
  owner_id?: string;
  name?: string | null;
  created_at?: Date | null | string;
  updated_at?: Date | null | string;
  type?: string | null;
  description?: string | null;
  frequency?: string | null;
  tags?: string[];
  voice_segment_title?: boolean | null;
  hidden?: boolean | null;
  stream_type?: string | null;
  days_of_week?: string[];
  // The prisma type is "Prisma.Decimal", which is serialized as a String in JSON
  avg_duration?: string | null;
  requires_intro?: boolean | null;
  episode_count?: number | null;
  // The prisma type is "Prisma.Decimal", which is serialized as a String in JSON
  recent_frequency?: string | null;
  price_plan?: string | null;
  contains_ads?: boolean | null;
  text_intro_appropriate?: string | null;
  image_url?: string | null;
  follow_model?: string | null;
  publish_uri?: string | null;
  link_in_bio?: string | null;
  owner?: { goes_by: string; id: string; handle: string } | null;
  restriction_mode?: StreamRestrictionMode;
  default_ttl?: number | null;
  message_creator_enabled: boolean;
  recommend_score?: number;
  image_bucket?: string | null;
  image_key?: string | null;
  payment_id?: string | null;
  StripePrice?: PriceId[] | null;
  sms_code?: string | null;
  AliasMapping?: any | null;
  funding?: PodcastFunding[];
};

export type StreamUser = {
  contribution_type: ContributionType;
  id: string;
  image_url?: string;
  name: string;
  row_id: number | null;
  stream: Stream;
  stream_id: string;
  user?: User;
  user_id?: string;
};

export type SegmentUser = {
  contribution_type: ContributionType;
  id: string;
  image_url?: string;
  name: string;
  segment_id: string;
  source: string;
  user?: User;
  user_id?: string;
};

export type PaginatedData<T> = {
  offset: number;
  limit: number;
  totalCount: number;
  data: T[];
};

export type UserAggregation = {
  row_id?: number;
  user_id?: string;
  type?: string | null;
  day_of_week?: number | null;
  time_local?: Date | null;
  timezone?: string | null;
  time_implied_utc?: Date | null;
  target_duration?: number | null;
  hour_local?: number | null;
  user_aggregation_id?: string;
};

export type TwilioMessageEvent = {
  row_id: number;
  id: string;
  user_id?: string | null;
  from_number?: string | null;
  to_number: string;
  message?: string | null;
  message_sid: string;
  messaging_service_sid: string;
  num_media?: number | null;
  media_attachments?: any | null;
  opt_out_type?: string | null;
  status: string;
  error_code?: string | null;
  error_message?: string | null;
  received_at: Date;
};

export enum StreamRestrictionAccess {
  None = 'None',
  Invited = 'Invited',
  Paid = 'Paid',
}

export type AggregationStreamInclude = {
  row_id: number | null;
  user_id: string;
  stream_id: string;
  created_at: Date | null;
  updated_at?: Date | null;
  distribution_pref?: string | null;
  user_aggregation_id: string;
  status?: string | null;
  stream?: Stream | null;
  restricted_access: StreamRestrictionAccess;
};

export type Segment = {
  SegmentUser?: SegmentUser[] | null;
  row_id?: number;
  id: string;
  uploaded_at?: string | null;
  uploaded_by?: string | null;
  upload_file_s3?: any | null;
  upload_metadata?: any | null;
  upload_source?: string | null;
  publish_future_at?: string | null;
  published_at?: string | null;
  published_by?: string | null;
  publish_uri?: string | null;
  publish_cdn?: string | null;
  publish_metadata?: any | null;
  title?: string | null;
  stream_id: string | null;
  stream?: Stream | null;
  transcoded_at?: string | null;
  transcoded_file_s3?: any | null;
  duration?: string | null;
  polly_task_id?: string | null;
  auto_publish?: boolean | null;
  number_in_series?: number | null;
  notes?: string | null;
  UserSegmentRating?: UserSegmentRating[];
  restricted?: boolean | null;
  image_url?: string | null;
};

export type UserSegmentEvent = {
  user_id: string;
  event: string;
  updated_at: string;
  segment_id: string;
  segment: Segment;
};

export type UserSegmentRating = {
  rating: string;
};

export type UserSetting = Record<'hour_local' | 'sms.optOutStatus' | 'timezone', string>;

export type StreamGroup = {
  category: {
    publishUri: string;
    label: string;
  };
  streams: Array<Stream> | null;
};

export type StreamInvitationAvailability = {
  available: boolean;
  id: string;
};

export type OnboardingPlaylistStream = {
  created_at: string;
  playlist_id: string;
  row_id: number;
  stream: Stream;
  stream_id: string;
  updated_at: string;
  image_url?: string;
  name?: string;
  id?: string | number;
};

export type OnboardingPlaylist = {
  created_at: string;
  description: string;
  image_url: string;
  name: string;
  headline?: string;
  playlist_id: string;
  priority: number;
  publish_uri?: string | null;
  row_id: number;
  title: string;
  updated_at: string;
  OnboardingPlaylistStream: OnboardingPlaylistStream[];
};
