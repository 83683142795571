import { PlaylistSegment } from '../lib/audio/PlaylistSegment';

export enum PlaybackSpeed {
  One = 1,
  OnePointTwo = 1.2,
  OnePointFive = 1.5,
  Two = 2,
}

export enum PlaybackState {
  None = 'None',
  Buffering = 'Buffering',
  Paused = 'Paused',
  Stopped = 'Stopped',
  Playing = 'Playing',
}

export enum Sonic {
  Start = 'Start',
  End = 'End',
  Next = 'Next',
}

export type AudioError = unknown;

export interface Player {
  speed: number;
  readonly duration: number;
  readonly position: number;
  readonly error: AudioError;
  readonly isPlaying: boolean;
  readonly timeRemaining: number;
  readonly percentPlayed: number;
  readonly isAudioLoaded: boolean;
  play: () => boolean;
  pause: () => void;
  loadAudio: () => void;
  stop: () => void;
  seek: (newPosition: number) => void;
  unload: () => void;
}

export interface PlaylistSegmentMetadata {
  readonly duration: number;
  readonly playlistSegment: PlaylistSegment;
  readonly timeRemaining: number;
  readonly percentPlayed: number;
  readonly position: number;
}

export type Playlist = {
  id: string;
  createdAt: string;
  segments: Array<PlaylistSegment>;
};

export enum PlaylistEventType {
  StateChanged = 'StateChanged',
  SpeedChanged = 'SpeedChanged',
  SegmentChanged = 'SegmentChanged',
  PlaylistChanged = 'PlaylistChanged',
  PositionChanged = 'PositionChanged',
}

export type PlaylistEvent = {
  type: PlaylistEventType;
  name?: string;
  state?: PlaybackState;
  speed?: PlaybackSpeed;
};

export type PlaylistEventHandler = (event: PlaylistEvent) => void;
