/**
 * We can use this as the single point of source to log & save errors to datadog
 *
 * @param error
 * @param info
 */

export const errorLogger = (error: Error, info: React.ErrorInfo) => {
  console.error(error, info);
};
