import dayjs from 'dayjs';

// TODO Make this configurable somehow, like settings or query string or admin status
export let isDebugEnabled = false;

export function debug(message: string) {
  if (isDebugEnabled) {
    console.debug(`${dayjs().format('mm:ss')}: ${message}`);
  }
}
