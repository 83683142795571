import { FC } from 'react';
import { ContributionType } from './api';

export * from './api';
export * from './api-utils';
export * from './audio';
export * from './layout';
export * from './page';
export * from './playlist';
export * from './tracking';

export type Contributor = {
  id: string;
  image_url?: string;
  name: string;
  user_id?: string;
  contribution_type: ContributionType;
  handle?: string;
};
export type RouterQuery = string | string[] | undefined;
export interface I18nPhoneFormValues {
  phone: string;
  phoneE164: string;
}

export type SvgFC = FC<SvgProps>;

export type SvgProps = {
  strokeWidth?: string;
  svgClass?: string;
  pathClass?: string;
};

export type AnchorTarget = '_blank' | '_self' | '_parent' | '_top';
