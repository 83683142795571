import '../styles/globals.css';
import type { AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import { get } from '../lib/api-client';

import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallBackComponent from '../components/ErrorFallBackComponent';
import { errorLogger } from '../lib/util/error-utils';
import { NextPageWithLayout, Sonic } from '../types';
import PlaylistPlayer from '../lib/audio/PlaylistPlayer';
import PageBoundary from '../components/PageBoundary';
import { initializeDataDog } from '../lib/datadog';
import { useUser } from '../lib/hooks/user';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

const GOOGLE_TAG_MANAGER_ID = 'GTM-5HHQB5T';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

PlaylistPlayer.configureSonics({
  [Sonic.Start]: 'https://p.getjam.co/sonic/start.mp3',
  [Sonic.End]: 'https://p.getjam.co/sonic/end.mp3',
  [Sonic.Next]: 'https://p.getjam.co/sonic/next.mp3',
});

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  const { data: user } = useUser();

  useEffect(() => {
    initializeDataDog();
  }, []);

  useEffect(() => {
    if (user) {
      datadogRum.setUser({
        id: user.id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV == 'production') {
      TagManager.initialize({ gtmId: GOOGLE_TAG_MANAGER_ID });
    }
  }, []);

  return (
    <SWRConfig
      value={{
        fetcher: (key: string | Array<any>) => {
          if (Array.isArray(key)) {
            // https://swr.vercel.app/docs/arguments#multiple-arguments
            return get(key[0], key[1]);
          } else {
            return get(key);
          }
        },
      }}
    >
      <ErrorBoundary
        FallbackComponent={({ error }) => {
          return <ErrorFallBackComponent {...{ errorMessage: error.message }} />;
        }}
        onError={errorLogger}
      >
        <PageBoundary userReq={Component.userRequirement}>{getLayout(<Component {...pageProps} />)}</PageBoundary>
      </ErrorBoundary>
    </SWRConfig>
  );
}

export default MyApp;
